* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

.quiz {
    border: 2px solid black;
    width: 100%;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.results {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid black;
    background-color: rgba(135, 116, 238, 0.9);
    font-size: 40px;
    font-width: 500;
}

.green-color {
    color: green;
}

.red-color {
    color: #ee3a3a;
}

.speech {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: rgba(135, 116, 238, 0.7);
}

.speech .speech-buttons {
    font-size: 800%;
    cursor: pointer;
    border-radius: 50%;
    border: 0;
    background-color: rgba(255, 255, 255, 0);
}

.speech .speech-buttons:hover {
    background-color: rgba(255, 255, 255, 0.25);
}

.next-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 30px;
    background-color: orange;
    border: 1px solid black;
    cursor: pointer;
}

.options {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    border-top: 1px solid black;
    background-color: rgba(135, 116, 238, 0.9);
}

.options li {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
    width: calc(50% - 5px);
    height: 40px;
    flex-wrap: wrap;
    overflow: hidden;
    cursor: pointer;
}

.options li.correct {
    background-color: greenyellow;
}

.options li.wrong {
    background-color: #fa2e2e;
}

.options li:hover,
.options li:active {
    background-color: orange;
}

.options input[type='number'] {
    width: 200px;
    height: 30px;
    font-size: 25px;
}

.options button {
    width: 30px;
    height: 30px;
    font-size: 20px;
}

@media (max-width: 600px) {
    body {
        background-color: black;
    }
    .speech {
        height: Calc(100vh - 40px - 60px);
    }

    .quiz {
        height: 90vh;
    }
}
